module.exports={
   "caseSensitive":false,
   "collapseBooleanAttributes":true,
   "collapseInlineTagWhitespace":false,
   "collapseWhitespace":true,
   "conservativeCollapse":false,
   "decodeEntities":true,
   "html5":true,
   "includeAutoGeneratedTags":false,
   "keepClosingSlash":false,
   "minifyCSS":true,
   "minifyJS":true,
   "preserveLineBreaks":false,
   "preventAttributesEscaping":false,
   "processConditionalComments":true,
   "processScripts":[
      "text/html"
   ],
   "removeAttributeQuotes":true,
   "removeComments":true,
   "removeEmptyAttributes":true,
   "removeEmptyElements":false,
   "removeOptionalTags":true,
   "removeRedundantAttributes":true,
   "removeScriptTypeAttributes":true,
   "removeStyleLinkTypeAttributes":true,
   "removeTagWhitespace":true,
   "sortAttributes":true,
   "sortClassName":true,
   "trimCustomFragments":true,
   "useShortDoctype":true
}
